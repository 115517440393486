import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineLinkedin} from 'react-icons/ai'
import {AiOutlineDribbble} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'

export const links = [
    {id: 1, link: '#', title: 'Home'},
    {id: 2, link: '#about', title: 'About'},
    // {id: 3, link: '#services', title: 'Experiences'},
    {id: 4, link: '#portfolio', title: 'Portfolio'},
    {id: 5, link: '#contact', title: 'Contact'}
]


export const socials = [

    
    {id: 2, link: 'https://www.linkedin.com/in/cyrilleasima/', icon: <AiOutlineLinkedin/>},
    // {id: 3, link: 'https://dribbble.com', icon: <AiOutlineDribbble/>},
    {id: 4, link: 'https://github.com/Cyrille-Asima', icon: <AiFillGithub/>}
]

