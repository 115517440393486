import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.png'
import Image3 from '../../assets/project3.png'
import Image4 from '../../assets/project4.png'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project8.jpg'
import Image9 from '../../assets/project9.jpg'

const data = [
    {
        id: 1,
        category: 'Full Stack',
        image: Image1,
        title: "Medvice (Full Stack)",
        desc: "A full stack website for small pharmacy owners to purchase pharmacy equipments and devices",
        demo: 'https://www.medvicedemo.dev',
        github: 'https://github.com/Cyrille-Asima/medical'
    },
    {
        id: 2,
        category: 'Full Stack',
        image: Image2,
        title: "Tree House (Full Stack)",
        desc: "A full stack website using firebase and leaflet intended for the rent and sale off tree houses.",
        demo: 'https://treehouse-three.vercel.app',
        github: 'https://github.com/Cyrille-Asima/treehouse'
    },
    {
        id: 3,
        category: 'frontend',
        image: Image3,
        title: "E-ASIMA (Frontend)",
        desc: "An eCommerce frontend focused  website intended for customers to shop their favorite products",
        demo: 'https://myeladshop.com',
        github: 'https://github.com/Cyrille-Asima/vshop'
    },
    {
        id: 4,
        category: 'frontend',
        image: Image4,
        title: "Food Corner (Frontend)",
        desc: "A frontend focused food ordering website. Currently still under development  ",
        demo: 'https://cmrfoodstop.com/',
        github: 'https://github.com/Cyrille-Asima/cmrfood'
    },
    // {
    //     id: 5,
    //     category: 'uiux',
    //     image: Image5,
    //     title: "Project Title Five (UIUX)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! ",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // },
    // {
    //     id: 6,
    //     category: 'frontend',
    //     image: Image6,
    //     title: "Project Title Six (Frontend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // },
    // {
    //     id: 7,
    //     category: 'frontend',
    //     image: Image7,
    //     title: "Project Title Seven (Frontend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // },
    // {
    //     id: 8,
    //     category: 'backend',
    //     image: Image8,
    //     title: "Project Title Eight (Backend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // },
    // {
    //     id: 9,
    //     category: 'frontend',
    //     image: Image9,
    //     title: "Project Title Nine (Frontend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // }
]


export default data