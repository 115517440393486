import React from 'react'
import './about.css'
import AboutImage from '../../assets/about.jpg'
import CV from '../../assets/cv.pdf'
import data from './data'
import {FaFileDownload} from 'react-icons/fa'
import Card from './../../components/Card'

const About = () => {
return (
<section id="about" data-aos='fade-in'>
    <div className="container about_container">
        <div className="about_left">
            <div className="about_portrait">
                <img src={AboutImage} alt="About Image" className='about_img' />
            </div>
        </div>

        <div className="about_right">
            <h2>About me</h2>
            <div className="about_cards">
                {
                    data.map(item=> (
                        <Card key={item.id} className='about_card'>
                            <span className='about_card-icon'>{item.icon}</span>
                            <h5>{item.title}</h5>
                            <small>{item.desc}</small>
                        </Card>
                    ))
                }
            </div>
            <p>
            My name is Cyrille and I am currently working as a pharmacist on the path to becoming a software engineer. I have been practicing as a pharmacist
            for about three years now. I finally decided to follow my dream of becoming a developer by attending a coding bootcamp which I completed 
            December of 2022.
            </p>
            <p>
            Although I love being a pharmacist, I have always been interested in software. I became interested in software during my college years.
            I had several friends in computer science programs who introduced me to programming. I  became fascinated with the idea of how software works
            and the ability to see the progression.    
            </p>
        
            <a href={CV} download className='btn primary'> Download CV <FaFileDownload/></a>

        </div>

    </div>
</section>
)
}

export default About