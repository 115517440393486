import React from 'react'
import './header.css'
import logo from '../../assets/logo.jpg'
import data from './data'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect  } from 'react'


const Header = () => {
    useEffect(() => {
        AOS.init({duration:2000})
    },[])


return (
    <header id='header'>
        <div className="container header_container">
            <div className="header_profile" data-aos='fade-in'>
                <img src={logo} alt="Header Portrait" />
            </div>

            <h3 data-aos='fade-up'>Cyrille Asima</h3>
            <p data-aos='fade-up'>
            Pharmacist transitioning into Software Development after completing Coding Dojo Software Development Bootcamp. 
            Developed strong communication, problem solving and critical thinking skills as a pharmacist as well as attention to detail. 
            Full stack development hands-on experience using Python, React Js, SQL, MongoDB, and other frameworks.
            </p>
            <div className="header_cta" data-aos='fade-up'>
                <a href='#contact' className='btn primary'>Contact Me</a>
                <a href='#portfolio' className='btn light'>My Work</a>
            </div>
            <div className="header_socials">
                {
                    data.map(item => <a key={item.id} href={item.link} target='_blank' rel='noopener noreferrer'>{item.icon}</a>)
                }
            </div>

        </div>
    </header>
)
}

export default Header