import './portfolio.css'
import data from './data'
import { useState } from 'react'
import ProjectsCategories from './ProjectsCategories'
import Projects from './Projects'

const Portfolio = () => {
    const [projects, setProjects] = useState(data)

    const categories = data.map(item => item.category)
    const uniqueCategories = ['all', ...new Set(categories)]

    const filterProjectsHandler = (category) => {
        if(category ==='all') {
            setProjects(data)
            return
        }
        const filterProjects = data.filter(project => project.category === category)
        setProjects(filterProjects)
    }




return (
    <section id="portfolio">
        <h2>Recent Projects</h2>
        <p>
            These are some of the projects i have recently worked on.
        </p>
        <div className="container portfolio_container">
            <ProjectsCategories categories={uniqueCategories} onFilterProjects={filterProjectsHandler}/>
            <Projects projects={projects}/>
        </div>
        </section>
)
}

export default Portfolio