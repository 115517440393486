import React from 'react'
import data from './data'
import './contact.css'

const Contact = () => {
  return (
<section id="contact">
  <h2>Get In Contact</h2>
  <p>
    Send me a message via email using the link below!
  </p>

  <div className="container contact_container" data-aos='fade-up'>
    {
      data.map(contact => <a key={contact.id} href={contact.link} target='_blank' rel='noopener noreferrer'>
        {contact.icon}</a>)
    }
  </div>
</section>  )
}

export default Contact